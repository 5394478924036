import {environment} from '@/commons/configs/Config';
import Cookies from "js-cookie";

export async function createFaq(title, content) {
    const userToken = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/faq/create', {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "title": title,
            "content": content
        })
    })

    const rest = await result;
    return rest
}

export async function deleteFaq(id) {
    const userToken = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/faq/delete', {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "id": id
        })
    })

    const rest = await result;
    return rest
}

export async function getFaqs() {
    const result = fetch(environment.apiUrlOne + '/faq/get', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}