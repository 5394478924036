import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';
import { Base64 } from 'js-base64';

const baseRequestUrl = environment.apiUrlTwo + "/files";

// Funzione per iniziare l'upload del file e ottenere le informazioni necessarie
export async function uploadFile(file, savePath, addInfo, keepName = false) {
    const finalPath = `${savePath}`;
    const requestUrl = `${baseRequestUrl}/uploadRequest`;

    const response = await fetch(requestUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
        },
        body: JSON.stringify({
            fileName: file.name,
            fileDirectory: finalPath,
            keepName: keepName
        }),
    });

    if (response.ok) {
        const fileUploadInfo = await response.json();
        const chunkSize = fileUploadInfo.maxChunkSize;
        const uid = fileUploadInfo.fileUUID;
        let uploadChunksResp = await uploadChunks(file, fileUploadInfo, chunkSize)
        if(uploadChunksResp) {
            return uid;
        }else{
            addInfo("alert", "Problema nel caricamento", "C'è stato un problema nel caricamento di parte del File!<br>Riprova più tardi, se il problema persiste apri un report con il codice errore: #FUCE!", 10);
        }
    } else {
        addInfo("warning", "Problema nel caricamento", "C'è stato un problema nel caricamento del file!<br>Riprova più tardi, se il problema persiste apri un report con il codice errore: #FUDE!", 10);
    }
    return null;
}

// Funzione per caricare i chunk del file
export async function uploadChunks(file, fileUploadInfo, chunkSize) {
    const uploadUrl = `${baseRequestUrl}/uploadChunk`;
    let allGood = true;

    const fileData = await file.arrayBuffer();
    const totalChunks = Math.ceil(fileData.byteLength / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, fileData.byteLength);
        const chunk = fileData.slice(start, end);

        let binary = '';
        const bytes = new Uint8Array(chunk);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64Chunk = btoa(binary);

        const response = await fetch(uploadUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
            },
            body: JSON.stringify({
                fileUUID: fileUploadInfo.fileUUID,
                chunkNumber: i,
                totalChunks: totalChunks,
                chunkBase64: base64Chunk,
            }),
        });

        if (!response.ok) {
            allGood = false;
            console.log(response)
            break;
        }
    }
    return allGood;
}

export async function getFileUrl(uuid) {
    const requestUrl = `${baseRequestUrl}/getFile/` + uuid;

    try {
        const response = await fetch(requestUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
            },
        });

        if (!response.ok) {
            return null;
        }

        // Estrai l'URL pubblico del file dalla risposta
        return await response.text();

    } catch (error) {
        console.error("Errore durante la chiamata all'API:", error);
        return null;
    }
}