// "@/commons/tickets/CommonReportsPage.jsx"

import React, {useEffect, useMemo, useRef, useState} from "react";
import moment from "moment";

// Stile comune
import styles from "./CommonReportsPage.module.css";

// Se hai un file "ReportStyles.module.css" con classi come reportMainBox, importalo pure
// Se preferisci tenerle direttamente in CommonReportsPage.module.css, va bene uguale.
// Esempio:
// import reportStyles from "@/commons/styles/ReportStyles.module.css";
import Post from "@/commons/objects/post/Post";
import {getMinecraftOther} from "@/commons/utils/others/Account";
import {getMinecraftHead} from "@/commons/utils/others/Users";
import Redirector from "@/commons/utils/Redirects";

/**
 * colorazione status, restituisce stringa HTML
 */
function coloredStatus(status) {
    switch ((status || "").toUpperCase()) {
        case "HIDED":
            return `<span style='color:gray; font-weight:bold'>NASCOSTO</span>`;
        case "CLOSED":
            return `<span style='color:darkred; font-weight:bold'>CHIUSO</span>`;
        case "OPEN":
            return `<span style='color:green; font-weight:bold'>APERTO</span>`;
        default:
            return `<span style='color:goldenrod; font-weight:bold'>${(status || "").toUpperCase()}</span>`;
    }
}

/**
 * Mostra un singolo report. Carica avatar dal getMinecraftHead e
 * chiama onClick(reportId, event) al click.
 *
 * NOTA: Se usi un file "reportStyles" con .reportMainBox, .reportImage, ecc.,
 * sostituisci “styles.xxx” con “reportStyles.xxx” a piacere.
 */
function ReportItem({report, onClick}) {
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        (async () => {
            const mcData = await getMinecraftOther(report.user_id.id);
            const userName = report.user_id.username;
            const mcAvatar = await getMinecraftHead(mcData, userName);
            setAvatar(mcAvatar);
        })();
    }, [report.user_id]);

    const localDateTime = report.time;
    const parsedDate = moment(localDateTime).valueOf();
    const timeStr = moment(parsedDate).format("DD-MM-YYYY HH:mm:ss");

    function handleClick(event) {
        if (onClick) onClick(report.id, event);
    }

    return (
        <div className={styles.ReportBox} onClick={handleClick}>
            {/* user/avatar */}
            <div className={styles.reportUsername} id="report_username">
                {avatar && (
                    <img className={styles.reportImage} src={avatar} alt="avatar"/>
                )}
                <span>{report.user_id.username}</span>
            </div>
            {/* titolo */}
            <div className={styles.reportTitle} id="report_title">
                <span>{report.title}</span>
            </div>
            {/* categoria */}
            <div className={styles.reportCategory} id="report_category">
                <span>{report.category_id.name}</span>
            </div>
            {/* status */}
            <div className={styles.reportStatus}>
                <span dangerouslySetInnerHTML={{__html: coloredStatus(report.status)}}/>
            </div>
            {/* data/ora */}
            <div className={styles.reportTime}>
                <span>{timeStr}</span>
            </div>
        </div>
    );
}

/**
 * CommonReportsPage:
 *  - Filtri open/closed
 *  - Ricerca testuale
 *  - Paginazione
 *  - Caricamento asincrono (fetchReports)
 *  - Opzionale: pulsante "Crea nuovo Report"
 *
 * Props:
 *  - pageTitle
 *  - fetchReports(filter, page, itemsPerPage) => Promise di {content:[], totalElements}
 *  - onReportClick(reportId, event)
 *  - newReportButtonUrl (string) => se presente, appare pulsante "Crea nuovo Report"
 */
function CommonReportsPage({
                               pageTitle = "Reports",
                               fetchReports,
                               onReportClick,
                               newReportButtonUrl = null,
                           }) {
    // Filtri open/closed
    const [isOpenFilter, setIsOpenFilter] = useState(true);
    const [isClosedFilter, setIsClosedFilter] = useState(false);

    // Ricerca
    const [searchTerm, setSearchTerm] = useState("");
    const prevSearchLen = useRef(0);

    // Paginazione
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [maxPage, setMaxPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    // Stato array report
    const [reports, setReports] = useState([]);
    // Loading => blocca i filtri mentre carica
    const [isLoading, setIsLoading] = useState(false);

    function getCurrentFilter() {
        if (isOpenFilter && !isClosedFilter) return "open";
        if (!isOpenFilter && isClosedFilter) return "closed";
        return "all";
    }

    // Carica i report
    async function loadReports() {
        setIsLoading(true);
        try {
            const filter = getCurrentFilter();
            const data = await fetchReports(filter, page, itemsPerPage);
            if (data && data.content) {
                setTotalItems(data.totalElements || 0);
                const newPosts = data.content.map((p) => new Post(p));
                setReports(newPosts);

                const newMaxPage = Math.ceil((data.totalElements || 0) / itemsPerPage);
                setMaxPage(newMaxPage === 0 ? 1 : newMaxPage);
                if (page >= newMaxPage && newMaxPage > 0) {
                    setPage(newMaxPage - 1);
                }
            }
        } catch (err) {
            console.error("Errore loadReports:", err);
        } finally {
            setIsLoading(false);
        }
    }

    // Ricarica quando cambiano page/itemsPerPage/filtri
    useEffect(() => {
        loadReports();
    }, [page, itemsPerPage, isOpenFilter, isClosedFilter]);

    // Logica "ricerca => itemsPerPage = totalItems"
    useEffect(() => {
        const oldLen = prevSearchLen.current;
        const newLen = searchTerm.length;

        if (oldLen === 0 && newLen > 0) {
            if (itemsPerPage < totalItems) setItemsPerPage(totalItems);
        } else if (newLen === 0 && oldLen > 0) {
            if (itemsPerPage >= totalItems) setItemsPerPage(5);
        }
        prevSearchLen.current = newLen;
    }, [searchTerm, itemsPerPage, totalItems]);

    // Filtraggio locale
    const filteredReports = useMemo(() => {
        if (!searchTerm) return reports;
        const up = searchTerm.toUpperCase();
        return reports.filter((rep) => {
            const t = rep.title?.toUpperCase() || "";
            const c = rep.category_id?.name?.toUpperCase() || "";
            const u = rep.user_id?.username?.toUpperCase() || "";
            return t.includes(up) || c.includes(up) || u.includes(up);
        });
    }, [searchTerm, reports]);

    // "X-Y/totalItems"
    const itemsString = (() => {
        if (totalItems === 0) return "0";
        const start = page * itemsPerPage + 1;
        const end = page * itemsPerPage + reports.length;
        return `${start}-${end}/${totalItems}`;
    })();

    // Gestione pagina
    function handlePageChange(newPage) {
        if (isLoading) return;
        if (newPage < 0) setPage(0);
        else if (newPage >= maxPage) setPage(maxPage - 1);
        else setPage(newPage);
    }

    // Gestione itemsPerPage
    function handleItemsPerPageChange(e) {
        if (isLoading) return;
        setItemsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    }

    // Switch filtri open/closed
    function switchFilter(input) {
        if (isLoading) return;
        if (input === "open") {
            const nextOpen = !isOpenFilter;
            if (nextOpen && isClosedFilter) setIsClosedFilter(false);
            setIsOpenFilter(nextOpen);
        } else {
            const nextClose = !isClosedFilter;
            if (nextClose && isOpenFilter) setIsOpenFilter(false);
            setIsClosedFilter(nextClose);
        }
    }

    // Click report
    function handleReportClick(reportId, event) {
        if (onReportClick) onReportClick(reportId, event);
    }

    return (
        <div className={styles.CommonReportsPage}>
            <h2>{pageTitle}</h2>
            <span>Reports: {itemsString}</span>

            {/* Filtri e search */}
            <div className={styles.ReportsPageLayout}>
                <div className={styles.filtersRow}>
                    <div className={styles.statusFilter}>
                        <span>Stato</span>
                        <label>
                            <input
                                type="checkbox"
                                disabled={isLoading}
                                checked={isOpenFilter}
                                onChange={() => switchFilter("open")}
                            />{" "}
                            Aperto
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                disabled={isLoading}
                                checked={isClosedFilter}
                                onChange={() => switchFilter("close")}
                            />{" "}
                            Chiuso
                        </label>
                    </div>
                </div>

                {/* Elenco reports */}
                <div>
                    <div className={styles.filtersRow}>
                        Cerca: <input
                            type="text"
                            placeholder="Cerca Report"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {newReportButtonUrl && (
                            <><br/>
                                <button
                                    disabled={isLoading}
                                    onClick={(e) => Redirector.send(newReportButtonUrl, e)}
                                >
                                    Crea nuovo Report
                                </button>
                            </>
                        )}
                    </div>
                    <div className={styles.reportsContainer}>
                        {filteredReports.map((rep) => (
                            <ReportItem key={rep.id} report={rep} onClick={handleReportClick}/>
                        ))}
                    </div>
                </div>
            </div>

            {/* Paginazione */}
            <div className={styles.paginationRow}>
                <label>Reports per pagina: </label>
                <select
                    disabled={isLoading}
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className={styles.pagesSelector}
                >
                    {[5, 10, 20, 30].map((val) => (
                        <option key={val} value={val}>
                            {val}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.paginationRow}>
                <label>Pagina: </label>
                <button disabled={isLoading} onClick={() => handlePageChange(0)}>
                    &lt;&lt;
                </button>
                <button
                    disabled={isLoading}
                    onClick={() => handlePageChange(page - 1)}
                >
                    &lt;
                </button>
                <span>
            {page + 1}/{maxPage}
          </span>
                <button
                    disabled={isLoading}
                    onClick={() => handlePageChange(page + 1)}
                >
                    &gt;
                </button>
                <button
                    disabled={isLoading}
                    onClick={() => handlePageChange(maxPage - 1)}
                >
                    &gt;&gt;
                </button>
            </div>
        </div>
    );
}

export default CommonReportsPage;