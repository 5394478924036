import ApiInterface from "@/commons/api/ApiInterface";
import ServerPreview from "@/www/pages/server/objects/Main/ServerPreview";

class FileHandler {
    static async getFileList() {
        return await ApiInterface.getServersFiles()
    }

    static async saveFile(serverObj, fileName, addInfo) {
        // Prepariamo i dati per il salvataggio
        const payload = JSON.stringify({
            title: serverObj.title || "",
            description: serverObj.description || "",
            preview: serverObj.preview ||
                new ServerPreview(serverObj.title || "", serverObj.description || "")
        });

            // Eventuali controlli aggiuntivi sul payload
            if(await ApiInterface.saveServerFile(fileName, payload)){
                addInfo(
                    "success",
                    "Informazioni aggiornate",
                    "Le informazioni sul server " + serverObj.title + " sono state aggiornate!",
                    5
                )
            }else{
                addInfo(
                    "error",
                    "Informazioni non aggiornate",
                    "Si è verificato un errore nell'aggiornamento delle informazioni del server " + serverObj.title,
                    7
                )
            }
    }

    static async loadFile(fileName) {
        return await ApiInterface.getServerFile(fileName)
    }
}

export default FileHandler;