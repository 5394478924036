import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import React from "react";
import Redirect from "@/commons/utils/Components/Redirect";
import ReportsHome from "@/reports/pages/home/ReportsHome";
import Post from "@/reports/pages/post/Post";
import Tickets from "@/reports/pages/tickets/Tickets";
import Error from "@/www/pages/error/Error";
import {environment} from "@/commons/configs/Config";

const ReportsApp: React.FC = () => {
    let path = window.location.pathname;
    let loadAll = false;

    if (!path.toLocaleLowerCase().includes("error")) {
        localStorage.setItem("errorName", "pageerror");
    }
    if (!path.toLocaleLowerCase().startsWith("/file")) {
        loadAll = true;
    }

    const appContent = createBrowserRouter(
        [{
            path: '/', element: <ReportsHome/>
        }, {
            path: '/profile', element: <Redirect domain={environment.production ? "www" : "wwwdev"} to={"profile"}/>
        }, {
            path: '/faq', element: <Redirect domain={environment.production ? "www" : "wwwdev"} to={"faq"}/>
        }, {
            path: '/post/:id', element: <Post/>
        }, {
            path: '/tickets', element: <Tickets/>
        }, {
            path: '/home', element: <Redirect domain={environment.production ? "www" : "wwwdev"}/>
        }, {
            path: '/report', element: <Navigate to={"/"} replace/>
        }, {
            path: '/reports', element: <Navigate to={"/"} replace/>
        }, {
            path: '/admin', element: <Redirect domain={environment.production ? "admin" : "admindev"}/>
        }, {
            path: '*', element: <Error errorCode={"notfound"}/>
        }]
    )
    return (
        <RouterProvider router={appContent}/>
    )
}

export default ReportsApp;