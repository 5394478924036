import {environment} from '@/commons/configs/Config';
import Cookies from "js-cookie";
import ApiInterface from "@/commons/api/ApiInterface";

export async function createGroup(name, description, power, staffer = false) {
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/create', {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "name": name,
            "description": description,
            "power": power,
            "staffer": staffer
        })
    })

    const rest = await result;
    return rest
}

export async function getGroups(){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/getGroups', {
        method: 'GET',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}
export async function deleteGroup(groupID){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/delete/'+groupID, {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}
export async function getGroup(groupID){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/get/'+groupID, {
        method: 'GET',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}

export async function setPower(id, power){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/set/power/'+id+'/?power='+power, {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}
export async function setDescription(id, description){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/set/description/'+id+'/?description='+description, {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}
export async function setStaffer(id, staffer){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/set/staffer/'+id+'/?staffer='+staffer, {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}
export async function setIcon(id, iconLink){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/set/icon/'+id+'/?iconLink='+iconLink, {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}

export async function setPermission(groupID, permission, value){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/staff/groups/set/permission', {
        method: 'POST',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "groupId": groupID,
            "permission": permission,
            "value": value
        })
    })

    const rest = await result;
    return rest
}
export async function getPermissions(){
    const userToken= Cookies.get(environment.tokenName);
    const result= fetch(environment.apiUrlTwo + '/permissions/getList', {
        method: 'GET',
        headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
        }
    })

    const rest = await result;
    return rest
}

async function loadImage(group, addInfo){
    let gettedGroup = await getGroup(group);
    let json = await ApiInterface.elaborateJsonData(gettedGroup, addInfo)

    if(json == null) return
    let iconUID = json.iconUUID

    return iconUID;
}

async function loadDefaultImage(){
    let iconUID = await loadImage("default");
    if(iconUID == null || iconUID === ""){
        iconUID = null;
    }

    return iconUID
}

export async function loadRoleImage(groupId){
    const response= await fetch(environment.apiUrlTwo + '/staff/groups/getIcon/'+groupId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    if (!response.ok) {
        return null;
    }

    // Estrai l'URL pubblico del file dalla risposta
    return await response.text();
}