import GroupObject from "@/admin/pages/groups/objects/GroupObject";

class UserProfile {
    constructor() {
        this.email = "";
        this.emailVerified = false;
        this.id = 0;
        this.minecraftUid = "";
        this.staffGroup = "";
        this.status = "";
        this.uid = "";
        this.username = "";
    }


    /*Getter and Setter for all the fields*/
    getemail() {
        return this.email;
    }

    setemail(value) {
        this.email = value;
    }

    getemailVerified() {
        return this.emailVerified;
    }

    setemailVerified(value) {
        this.emailVerified = value;
    }

    getid() {
        return this.id;
    }

    setid(value) {
        this.id = value;
    }

    getminecraftUid() {
        return this.minecraftUid;
    }

    setminecraftUid(value) {
        this.minecraftUid = value;
    }

    setpassword(value) {
        this.password = value;
    }

    getstaffgroup() {
        return this.staffGroup;
    }

    setstaffgroup(value) {
        this.staffGroup = value;
    }

    getstatus() {
        return this.status;
    }

    setstatus(value) {
        this.status = value;
    }

    getuid() {
        return this.uid;
    }

    setuid(value) {
        this.uid = value;
    }

    getusername() {
        return this.username;
    }

    setusername(value) {
        this.username = value;
    }

    updateAll(json) {
        this.loadFromJson(json)
    }

    loadFromJson(jsonElement) {
        this.id = jsonElement["id"];
        this.uid = jsonElement["uid"];
        this.username = jsonElement["username"];
        this.email = jsonElement["email"];
        this.minecraftUid = jsonElement["minecraftUid"];
        this.staffGroup = jsonElement["staffGroup"];
        this.emailVerified = jsonElement["emailVerified"];
        this.status = jsonElement["status"];
    }
}

export default UserProfile;