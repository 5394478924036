class GroupObject {
    constructor(id, groupPower, groupName, groupDescription, permissions, icon, staffer = []) {
        this.id = id; // ID del gruppo
        this.groupPower = groupPower; // Potenza del gruppo
        this.groupName = groupName; // Nome del gruppo
        this.groupDescription = groupDescription; // Descrizione del gruppo
        this.icon = icon;
        this.staffer = staffer;
        this.permissions = permissions.map(permission => new PermissionObject(permission.permission, permission.value)); // Permessi del gruppo
    }
}

class PermissionObject {
    constructor(permission, value) {
        this.permission = permission; // Tipo di permesso
        this.value = value; // Valore del permesso (ad esempio true o false)
    }
}

export default GroupObject;