import {environment} from '@/commons/configs/Config';
import Cookies from "js-cookie";

export async function addComment(content, postId) {
    let token = Cookies.get(environment.tokenName);
    let data = {
        "content": content,
        "postId": postId
    }
    const result = fetch(environment.apiUrlOne + '/comment/create/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(data),
    });

    return await result;
}

export async function editCategory(postId, categoryId, text) {
    let token = Cookies.get(environment.tokenName);
    let data = {
        "text": text
    }
    const result = fetch(environment.apiUrlOne + '/posts/editCategory/' + postId + '/' + categoryId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(data),
    });

    return await result;
}

export async function getLightUserPosts(userId, page, items) {
    let token = Cookies.get(environment.tokenName);
    let data = {
        "page": page,
        "items": items
    }
    const result = fetch(environment.apiUrlTwo + '/posts/getUserPosts/' + userId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(data),
    });

    return await result;
}

export async function getImage(imageUid) {
    const result = fetch(environment.apiUrlOne + '/images/get/' + imageUid, {
        method: 'GET'
    });
    return await result;
}