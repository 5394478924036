import * as Details from './apiPages/Details.js';
import * as Faq from './apiPages/Faq.js';
import * as Account from './apiPages/Account.js';
import * as Tokens from './apiPages/Tokens.js';
import * as Notifications from './apiPages/Notifications';
import * as Posts from './apiPages/Posts';
import * as Report from './apiPages/Report';
import * as Users from './apiPages/Users';
import * as Utils from './apiPages/Utils';
import * as Downloads from './apiPages/Downloads';
import * as Categories from "./apiPages/Categories";
import * as Storage from "./apiPages/Storage"
import * as Files from "./apiPages/Files"
import * as Groups from "./apiPages/Groups"
import * as Servers from "./apiPages/Servers"

import * as ResponseHelper from "./helper/Reponses"

export default {
    ...Details,
    ...Categories,
    ...Faq,
    ...Account,
    ...Tokens,
    ...Notifications,
    ...Posts,
    ...Report,
    ...Users,
    ...Utils,
    ...Downloads,
    ...Storage,
    ...Files,
    ...Groups,
    ...Servers,
    ...ResponseHelper
};
