import React, {createContext, ReactNode, useContext, useEffect, useReducer} from 'react';
import styles from "./InfoBox.module.css";
import {v4 as uuidv4} from 'uuid';

type InfoType = 'warning' | 'alert' | 'success' | 'info';

interface Info {
    id: string;
    type: InfoType;
    title: string;
    text: string;
    status: 'creating' | 'created' | 'remove' | 'removing' | 'removed' | 'show';
}

type Action =
    | { type: 'add'; payload: Info }
    | { type: 'remove'; payload: string }
    | { type: 'update'; payload: { id: string; status: Info['status'] } };

type State = Record<string, Info>;

type InfoBoxContextType = {
    info: Info[];
    addInfo: (type: InfoType, title: string, text: string, autoRemoveTime?: number) => void;
    removeInfo: (id: string) => void;
    changeStatus: (id: string, status: Info['status']) => void;
};

const InfoBoxContext = createContext<InfoBoxContextType | undefined>(undefined);

const initialState: State = {};

function infoBoxReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'add':
            return {...state, [action.payload.id]: action.payload};
        case 'remove':
            const newState = {...state};
            delete newState[action.payload];
            return newState;
        case 'update':
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    status: action.payload.status,
                },
            };
        default:
            throw new Error('Unhandled action type');
    }
}

export const InfoBoxProvider = ({children}: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(infoBoxReducer, initialState);

    const addInfo = (type: InfoType, title: string, text: string, autoRemoveTime: number = 0) => {
        const id = uuidv4();
        dispatch({
            type: 'add',
            payload: {id, text, type, title, status: 'creating'}
        });
        setTimeout(() => dispatch({type: 'update', payload: {id, status: 'created'}}), 100);
        if (autoRemoveTime > 0) {
            setTimeout(() => dispatch({type: 'update', payload: {id, status: 'remove'}}), autoRemoveTime * 1000);
        }
    };

    const removeInfo = (id: string) => {
        dispatch({type: 'remove', payload: id});
    };

    const changeStatus = (id: string, status: Info['status']) => {
        dispatch({type: 'update', payload: {id, status}});
    };

    return (
        <InfoBoxContext.Provider value={{info: Object.values(state), addInfo, removeInfo, changeStatus}}>
            {children}
        </InfoBoxContext.Provider>
    );
};

export const useInfoBox = () => {
    const context = useContext(InfoBoxContext);
    if (!context) {
        throw new Error('useInfoBox must be used within a InfoBoxProvider');
    }
    return context;
};

export const InfoBox = () => {
    const {info, removeInfo, changeStatus} = useInfoBox();

    const handleRemoveInfo = (id: string) => {
        changeStatus(id, "remove");
    };

    useEffect(() => {
        info.forEach((item) => {
            let id = item.id;

            if (item.status === "created") {
                changeStatus(id, "show");
            }
            if (item.status === "remove") {
                changeStatus(id, "removing");
                setTimeout(() => {
                    changeStatus(id, "removed");
                    removeInfo(id);
                }, 1000);
            }
        });
    }, [info]);

    const getClass = (infoItem: Info) => {
        switch (infoItem.status) {
            case 'show':
                return styles.activeBox;
            case 'removing':
                return null;
            case 'removed':
                return styles.hidedBox;
            default:
                return '';
        }
    };

    const getTypeClass = (infoItem: Info) => {
        switch (infoItem.type) {
            case "warning":
                return styles.warning;
            case "alert":
                return styles.alert;
            case "success":
                return styles.success;
            case "info":
            default:
                return styles.info;
        }
    };

    const getText = (text: string) => {
        return text.replaceAll("<br>", "\n");
    };

    return (
        <div className={styles.infoBox__container}>
            {info.map((infoItem) => (
                <div
                    className={`${styles.infoBox__info} ${getClass(infoItem)} ${getTypeClass(infoItem)}`}
                    key={infoItem.id}
                >
                    <span className={styles.title}>{infoItem.title}</span>
                    <span className={styles.text}><pre>{getText(infoItem.text)}</pre></span>
                    <div
                        className={styles.closeBox}
                        onClick={() => handleRemoveInfo(infoItem.id)}
                    />
                </div>
            ))}
        </div>
    );
};