import React, {useEffect, useState} from "react";
import ApiInterface from "@/commons/api/ApiInterface";
import Cookies from 'js-cookie';
import {environment} from "@/commons/configs/Config"
import {useInfoBox} from "@/commons/infoBox/InfoBox"
import Redirects from "@/commons/utils/Redirects";
import BaseInfoBoxs from "@/commons/infoBox/BaseInfoBoxs";
import {getSubDomain} from "@/commons/utils/Domains";

const PageManager = () => {
    const {addInfo} = useInfoBox();
    const [finishedLoading, setFinishedLoading] = useState(false);
    let loggedPages = ["profile"];
    let notLoggedPages = ["access"];

    let loggedDomains = ["reports", "reportsdev"]
    let AdminDomains = ["panel", "admin", "admindev"]

    let isLogged = false;
    let isStaff = false;

    async function loadInfo() {
        isLogged = await ApiInterface.checkLogged();
        if (isLogged) {
            isStaff = await ApiInterface.isStaffer();
        }
        return;
    }

    function checkPage() {
        let page = window.location.pathname.replace("/", "");
        let pageDomain: string = getSubDomain(window.location.hostname);

        if (!isLogged) {
            if(pageIsAnyDomain(pageDomain, loggedDomains) || pageIsAnyDomain(pageDomain, AdminDomains)){
                Redirects.domainSend("www", "", {popupData: BaseInfoBoxs("notLogged")})
                return;
            }
            if (pageContains(page, loggedPages)) {
                Redirects.domainSend("www", "", {popupData: BaseInfoBoxs("notLogged")})
                return;
            }
        }
        if (isLogged) {
            if (pageContains(page, notLoggedPages)) {
                Redirects.send("/", null, {popupData: BaseInfoBoxs("alreadyLogged")})
                return;
            }
            if (pageIsAnyDomain(pageDomain, AdminDomains)) {
                if (!isStaff) {
                    Redirects.domainSend("www", "", {popupData: BaseInfoBoxs("pageAccessDenied")})
                    return;
                }
            }
        }

        setFinishedLoading(true);
    }

    function pageContains(page: string, array: string[]) {
        for (let i = 0; i < array.length; i++) {
            if (page.includes(array[i])) {
                return true;
            }
        }
        return false;
    }

    function pageIsDomain(pageDomain: string, checkDomain: string) {
        return pageDomain.toLowerCase() === checkDomain.toLowerCase()
    }
    function pageIsAnyDomain(pageDomain: string, checkDomains: string[]) {
        for(let i = 0; i < checkDomains.length; i++) {
            if(pageDomain.toLowerCase() === checkDomains[i].toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    function checkPopUpData() {
        let url = new URL(window.location.href);
        let popupDataValues = url.searchParams.getAll('popupData'); // Lista di tutti i valori 'popupData'

        for (let i = 0; i < popupDataValues.length; i++) {
            let popupData = popupDataValues[i];

            if (popupData === undefined || popupData === null) {
                continue;
            }

            // decodifica la stringa base64
            let decodedString = atob(popupData);

            // converti la stringa in un oggetto JSON
            let json = JSON.parse(decodedString);

            // accedi alle proprietà dell'oggetto JSON
            let title = json.title;
            let text = json.text;
            let type = json.type;
            let time = json.time;

            addInfo(
                type, title, text, time);
            url.searchParams.delete('popupData');
            window.history.replaceState({}, '', url);
        }
    }

    useEffect(() => {
        loadInfo().then(r => {
            checkPage();
            checkPopUpData();

        });
    }, []);

    function preLoad() {
        if (localStorage.token !== undefined) {
            localStorage.removeItem(environment.tokenName);
        }
        if (localStorage.cookiesAccepted !== undefined) {
            localStorage.removeItem("cookiesAccepted");
        }

        let token = Cookies.get(environment.tokenName);

        if (token !== undefined) {
            Cookies.set('token', token, {expires: 30, path: '/', domain: 'eblcraft.it'});
        }

        let cookiesAccepted = Cookies.get("cookiesAccepted");
        if (cookiesAccepted !== undefined) {
            Cookies.set('cookiesAccepted', cookiesAccepted, {expires: 30, path: '/', domain: 'eblcraft.it'});
        }
    }

    preLoad()

    return (
        <div>
            {finishedLoading ? null : (<div className={"loadingPage"}><span className={"loadingCircle"}></span></div>)}
        </div>
    )

}

export default PageManager;