import './ProfilesManager.css';
import ApiInterface from "@/commons/api/ApiInterface"
import React, {useEffect, useState} from "react";
import Roles from "@/commons/objects/role/Roles";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import {useInfoBox} from "@/commons/infoBox/InfoBox";
import GroupObject from "@/admin/pages/groups/objects/GroupObject";
import Redirects from "@/commons/utils/Redirects";
import UserProfile from "@/commons/objects/user/UserProfile";
import UserProfileObject from "./objects/UserProfileObject";

const ProfilesManager = () => {
    const {addInfo} = useInfoBox();
    let [getTotalUsers, setTotalUsers] = useState(0);
    let [getRegisteredUsers, setRegisteredUsers] = useState(0);
    let [getProfiles, setProfiles] = useState([]);
    let [getProfilesObjects, setProfilesObjects] = useState([]);
    let [roles, setRoles] = useState(new Roles());
    let [getSearch, setSearch] = useState("");
    let [searchInitialized, setSearchInitialized] = useState(false);
    let [viewer, setViewer] = useState();
    let [viewerInitialized, setViewerInitialized] = useState(false);
    let page = 0;
    let maxPages = 0;
    let itemsPerPage = 10;

    const api = ApiInterface;

    function updateRole(newRole) {
        let cRoles = roles;
        cRoles.addRole(newRole);
        setRoles(cRoles);
    }

    async function loadSelf() {
        const self = await ApiInterface.getSelf();
        await setViewer(self)
    }

    async function loadRoles() {
        const res = await ApiInterface.getGroups();
        if (res.status === 200) {
            const resJson = await res.json();
            resJson.forEach((group) => {
                const role = new GroupObject(group.id, group.groupPower, group.groupName, group.groupDescription, group.permissions, group.iconUUID, group.staffer);
                updateRole(role);
            });
        }
    }

    useEffect(() => {
        loadSelf();
    }, []);

    useEffect(() => {
        if (!viewerInitialized) {
            setViewerInitialized(true);
            return;
        }
        const loadData = async () => {
            await calcProfiles();
            await loadRoles();
            await loadProfiles();
            await loadPages();
        };

        loadData();
    }, [viewer]);

    useEffect(() => {
        if (getTotalUsers === getRegisteredUsers && getTotalUsers !== 0) {
            let doc = document.getElementById("searchUserInput");
            doc.disabled = false;
            doc.placeholder = "Cerca un utente";
        }
    }, [getTotalUsers]);

    useEffect(() => {
        if (!searchInitialized) {
            setSearchInitialized(true);
            return;
        }
        if (viewer === null || viewer === undefined) return;
        let input = document.getElementById("searchUserInput").value;
        setTimeout(() => {
            if (input.length > 0) {
                if (getSearch === input) {
                    search(input);
                } else {
                    return;
                }
            } else {
                loadItems();
            }
        }, 700)
    }, [getSearch]);

    const calcProfiles = async () => {
        await api.getLightUsers(itemsPerPage, 0, true).then(async (res) => {
            if (res.status !== 200) {
                api.sendToError("apiError");
            }
            const resJson = await res.json();
            setRegisteredUsers(resJson["totalElements"]);
            maxPages = resJson["totalPages"];
        })
    }

    const loadProfiles = async () => {
        let totUsers = 0;

        // Creiamo un array di promesse per caricare tutte le pagine simultaneamente.
        const promises = Array.from({length: maxPages}, (_, i) =>
            api.getLightUsers(itemsPerPage, i).then(async (res) => {
                if (res.status !== 200) {
                    api.sendToError("apiError");
                    return [];
                }

                const resJson = await res.json();

                const numberOfElements = resJson["numberOfElements"];
                totUsers += numberOfElements;

                // Convertiamo i dati in UserProfile
                return resJson["content"].map((userData) => {
                    const profile = new UserProfile();
                    profile.loadFromJson(userData);
                    return profile;
                });
            })
        );
        // Attendiamo il completamento di tutte le promesse
        const results = await Promise.all(promises);

        // Usiamo .flat() per unire tutti gli array di profili in un unico array
        const allProfiles = results.flat();

        // Aggiorniamo lo stato con i profili caricati
        setProfiles(allProfiles);
        setTotalUsers(totUsers);

        // Dopo aver popolato `getProfiles`, carichiamo la prima pagina
    };

    useEffect(() => {
        loadItems();
    }, [getProfiles])


    const loadPages = async () => {
        // Controlla limiti paginazione
        page = Math.max(0, Math.min(page, maxPages - 1));

        // Pulisci le pagine precedenti
        document.getElementById("userPrevPages").innerHTML = "";
        document.getElementById("userNextPages").innerHTML = "";

        // Pulsanti di navigazione
        const createPageButton = (text, newPage) => {
            const button = document.createElement("div");
            button.className = "profileManager__userPageButton";
            button.innerText = text;

            button.addEventListener("click", () => {
                page = newPage;
                loadItems();
                loadPages();
            });

            return button;
        };

        // Aggiungiamo pulsanti di navigazione
        if (page > 0) {
            document.getElementById("userPrevPages").appendChild(createPageButton("<<", 0));
            document.getElementById("userPrevPages").appendChild(createPageButton("<", page - 1));
        }
        if (page < maxPages - 1) {
            document.getElementById("userNextPages").appendChild(createPageButton(">", page + 1));
            document.getElementById("userNextPages").appendChild(createPageButton(">>", maxPages - 1));
        }

        // Aggiorna l'indicatore di pagina attuale
        updatePage();
    };

    const updatePage = async () => {
        document.getElementById("userPages").innerHTML = "";

        //Show current page / max pages
        let currentPageDiv = document.createElement("div");
        currentPageDiv.className = "profileManager__userPage";
        currentPageDiv.innerText = (page + 1) + "/" + maxPages;

        document.getElementById("userPages").appendChild(currentPageDiv);
    }

    const search = async (input) => {
        await clearItems();
        for (let i = 0; i < getTotalUsers; i++) {
            let profile = getProfiles[i];

            if (profile === undefined) {
                continue;
            }

            let username = profile.username.toLowerCase();
            if (username.includes(input.toLowerCase())) {
                await loadUser(profile);
            }
        }
    }

    const loadItems = () => {
        const startIndex = page * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        const visibleProfiles = getProfiles.slice(startIndex, endIndex);

        const userComponents = visibleProfiles.map((profile) =>
            createUser(profile)
        );

        setProfilesObjects([...userComponents]); // Usa una copia del nuovo array
    };

    const clearItems = async () => {
        setProfilesObjects([]);
    }

    async function updateUser(profile) {
        let index = getProfiles.findIndex((p) => p.uid === profile.uid);

        if (index !== -1) {
            let updatedProfiles = [...getProfiles];
            updatedProfiles[index] = (
                <UserProfileObject
                    key={profile.uid}
                    profile={profile}
                    viewer={viewer}
                    roles={roles}
                />
            );
            setProfilesObjects(updatedProfiles);
        }
    }

    const loadUser = async (profile) => {
        const newUserComponent = createUser(profile);

        setProfilesObjects((prevObjects) => [...prevObjects, newUserComponent]);
    }

    const createUser = (profile) => {
        return (
            <UserProfileObject
                key={profile.uid}
                profile={profile}
                viewer={viewer}
                roles={roles}
            />
        );
    };

    async function preSearch() {
        let input = document.getElementById("searchUserInput").value;
        if (input.length === 0) {
            setSearch("");
        } else {
            setSearch(input);
        }
    }

    return (
        <>
            <Background/>
            <div className={"profilesManager__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"profilesManager__content"}>
                    <div className="profilesManager__main">
                        <div className={"profileManager__title"}>Gestione profili utente</div>
                        <div className={"profileManager__description"}>Qui puoi gestire i profili degli utenti</div>
                        <div className={"profileManager__search"}>
                            <input
                                disabled={true}
                                type={"text"}
                                placeholder={"Attendi il caricamento"}
                                id={"searchUserInput"}
                                onInput={() => {
                                    preSearch();
                                }}
                            />
                        </div>
                        <div className={"profileManager_totalAccounts"}>
                            Utenti registrati:{" "}
                            {getTotalUsers === 0 ? (<><br/><span className={"loadingCircleTiny"}/></>) : (getTotalUsers)}
                        </div>
                        <button onClick={(e) => Redirects.send("/", e)} className={"back-button"}>
                            ⬅ Indietro
                        </button>
                        <br/>
                        <br/>
                        <div className={"profileManager__userList"} id={"userList"}>
                            {getProfilesObjects && getProfilesObjects.length > 0 ? (
                                getProfilesObjects
                            ) : (
                                <><span className={"loadingCircleTiny"}/><br/><br/></>
                            )}
                        </div>
                        <div className={"profileManager__userPages"}>
                            <div id={"userPrevPages"}></div>
                            <div id={"userPages"}></div>
                            <div id={"userNextPages"}></div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}
export default ProfilesManager;