import MainApp from "@/www/MainApp";
import ReportsApp from "@/reports/ReportsApp";
import AdminApp from "@/admin/AdminApp";

export const APPS = [
    {
        subdomain: "www",
        devSubdomain: "wwwdev",
        app: MainApp,
        main: true,
    }, {
        subdomain: "reports",
        devSubdomain: "reportsdev",
        app: ReportsApp,
        main: false
    }, {
        subdomain: "admin",
        devSubdomain: "admindev",
        app: AdminApp,
        main: false
    },
]

//TODO: add after finish:
/*
{
        subdomain: "panel",
        app: PanelApp,
        main: false
    }
{
        subdomain: "store",
        app: StoreApp,
        main: false
    }
    {
        subdomain: "link",
        app: LinkApp,
        main: false

 */