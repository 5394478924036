import './Header.css';
import HeaderButton from "./objects/HeaderButton";
import AccountHeader from "./objects/AccountHeader";
import {environment} from '@/commons/configs/Config';

import flame from "@/commons/resources/images/logos/fiamma.png"
import Redirects from "@/commons/utils/Redirects"
import {useEffect} from "react";
import {getSubDomain} from "@/commons/utils/Domains";

const Header = (props) => {
    let marginTop = props.marginTop;
    let marginBottom = props.marginBottom;

    function setMargins() {
        let topHeader = document.getElementsByClassName("header__firstEmpty")[0];
        let bottomHeader = document.getElementsByClassName("header__secondEmpty")[0];

        if (marginTop === undefined) {
            marginTop = 0;
        }
        if (marginBottom === undefined) {
            marginBottom = 0;
        }
        topHeader.style.marginTop = marginTop + "%";
        bottomHeader.style.height = marginBottom + "%";
    }

    useEffect(() => {
        setTimeout(setMargins, 5);
    }, [])


    const webNavbar = () => {
        return (
            <>
                <HeaderButton title={"Servers"} link={"/server"}/>
                <HeaderButton title={"Reports"} link={"/reports"}/>
                <HeaderButton title={"Store"} type={"evidenced"} link={"https://store.eblcraft.it"}/>
                <HeaderButton title={"Social"} link={"/social"}/>
                <HeaderButton title={"ErenBlaze"} link={"/erenblaze"}/>
            </>
        )
    }
    const reportsNavbar = () => {
        return (
            <>
                <HeaderButton title={"Miei Reports"} link={"/reports"}/>
                <HeaderButton title={"EBLCraft"} type={"evidenced"} link={"home"}/>
                <HeaderButton title={"FAQ"} link={"/faq"}/>
            </>
        )
    }
    const adminNavbar = () => {
        return (
            <>
                <HeaderButton title={"Utenti"} link={"/profiles"}/>
                <HeaderButton title={"Reports"} link={"/reports"}/>
                <HeaderButton title={"Sito"} type={"evidenced"} link={""} domain={"www"}/>
                <HeaderButton title={"Categorie"} link={"/categories"}/>
                <HeaderButton title={"Aiuti"} link={"https://github.com/EBLPlugins/ModerationFiles/"}/>
            </>
        )
    }
    const panelNavbar = () => {
        return (
            <>
                <HeaderButton title={"Servers"} link={"/servers"}/>
                <HeaderButton title={"Sito"} type={"evidenced"} link={""} domain={"www"}/>
                <HeaderButton title={"Pterodactyl"} link={""} domain={"adminpanel"}/>
            </>
        )
    }


    const getNavbar = () => {
        let subdomain = getSubDomain(window.location.hostname);
        switch (subdomain.toLowerCase()) {
            case "admin":
                return adminNavbar();
            case "panel":
                return panelNavbar();
            case "reports":
                return reportsNavbar();
            default:
                return webNavbar();
        }
    }

    getNavbar();

    return (
        <>
            {!environment.production && (
                <div className="header__developmentBanner">
                    Ambiente di sviluppo attivo
                </div>
            )}
            <div className={"header__firstEmpty"}>
                <img src={flame} alt={"flameLogo"} onClick={(e) => {
                    let subdomain = getSubDomain(window.location.hostname);
                    switch (subdomain.toLowerCase()) {
                        case "admin":
                            Redirects.send("/", e)
                            break;
                        case "reports":
                            Redirects.send("/", e)
                            break;
                        default:
                            Redirects.send("/", e)
                            break;
                    }
                }}/>
            </div>
            <div className={"accountHeader__section"}>
                <AccountHeader/>
            </div>
            <div className={"header__content"}>
                {getNavbar()}
            </div>

            <div className={"header__secondEmpty"}></div>
        </>
    )

}

export default Header;