export async function elaborateJsonData(result, addInfo){
    if (result.status === 418) {
        let json = await result.json();
        if (json != null && json !== "") {
            addInfo(json["object"]["type"], json["object"]["title"], json["object"]["text"], 7);
        }
    }else if (result.status === 200){
        let json = await result.json();
        return json;
    }
    return null;


}
export async function elaborateData(result, addInfo){
    if (result.status === 418) {
        let json = await result.json();
        if (json != null && json !== "") {
            addInfo(json["object"]["type"], json["object"]["title"], json["object"]["text"], 7);
        }
    }else if (result.status !== 200){
        console.error("Found a problem")
    }
    return null
}