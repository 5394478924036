import {environment} from '@/commons/configs/Config';
import Cookies from "js-cookie";

export async function getLightUsers(items, page, updateRequest = false) {
    const result = fetch(environment.apiUrlTwo + '/details/getUsers', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
        },
        body: JSON.stringify({
            items: items,
            page: page,
            updateRequest: updateRequest
        })
    })
    return await result;
}

export async function getLightUser(uid) {
    const res = await fetch(environment.apiUrlTwo + '/details/getUser/'+uid, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
        }
    });

    if (res.status !== 200) {
        return null;
    }
    return await res.json();
}

export async function getUser(userId) {
    const result = fetch(environment.apiUrlOne + '/details/account/' + userId, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
        },
    })
    return await result;
}

export async function updateRole(uid, newRoleAlias) {
    const result = fetch(environment.apiUrlOne + '/administration/updateRole/' + uid, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
        },
        body: JSON.stringify({
            role: newRoleAlias
        })
    });
    return await result;
}

export async function banuser(uid) {
    const result = fetch(environment.apiUrlOne + '/administration/banuser/' + uid, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
        }
    });
    return await result;
}

export async function unbanuser(uid) {
    const result = fetch(environment.apiUrlOne + '/administration/unbanuser/' + uid, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
        }
    });
    return await result;
}