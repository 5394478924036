import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';
import {getGroups} from "@/commons/api/apiPages/Groups";
import ApiInterface from "@/commons/api/ApiInterface";


export async function fetchTwitch() {
    const userToken = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/details/twitch/', {
        method: 'GET',
        headers: {
            'Authorization': userToken
        }
    })

    const rest = await result;
    return rest
}

export async function fetchMinecraft() {
    const userToken = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/details/minecraft/', {
        method: 'GET',
        headers: {
            'Authorization': userToken
        }
    })

    const rest = await result;
    return rest
}

export async function fetchAccount() {
    const userToken = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/details/account/', {
        method: 'GET',
        headers: {
            'Authorization': userToken
        }
    })

    const rest = await result;
    return rest
}

export async function getRoles() {
    const userToken = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/details/allRoles/', {
        method: 'GET',
        headers: {
            'Authorization': userToken
        }
    })

    const rest = await result;
    return rest
}

export async function hasPermission(permission){
    const userToken = Cookies.get(environment.tokenName);
    if(userToken == null) return false;

    const result = fetch(environment.apiUrlTwo + '/permissions/hasPermission/'+permission, {
        method: 'GET',
        headers: {
            'Authorization': userToken
        }
    })

    const rest = await result;
    return rest.status === 200;

}

export async function isStaffer() {
    //Get user role by account details
    const account = await fetchAccount();

    if (account.status !== 200) {
        return false;
    }
    const accountJson = await account.json();
    const userPower = accountJson["staffGroup"]["groupPower"];

    return userPower > 100;
}

export async function hasMc() {
    const mc = await fetchMinecraft();
    return mc.status === 200;

}

export async function isSub() {
    const twitch = await fetchTwitch();
    if (twitch.status !== 200) {
        return false;
    }
    try {
        const twitchJson = await twitch.json();
        let isBanned = twitchJson["banned"];
        if (isBanned === true) {
            return false;
        }

        return twitchJson["subActive"];
    } catch (e) {
        console.error("Twitch not connected for your account")
        return false;
    }
}

export async function isVip() {
    const twitch = await fetchTwitch();
    if (twitch.status !== 200) {
        return false;
    }
    try {
        const twitchJson = await twitch.json();
        let isBanned = twitchJson["banned"];
        if (isBanned === true) {
            return false;
        }

        return twitchJson["vip"];
    } catch (e) {
        console.error("Twitch not connected for your account")
        return false;
    }
}

export async function isEmailVerified() {
    //Get user role by account details
    const account = await fetchAccount();

    if (account.status !== 200) {
        return false;
    }
    const accountJson = await account.json();
    const isVerified = accountJson["emailVerified"];
    if (isVerified === true) {
        return true;
    }
    if (isVerified === false) {
        return false;
    }
}

export async function getBadge(role) {
    const result = fetch(environment.apiUrlOne + '/utils/images/roles/' + role, {
        method: 'GET'
    })

    const rest = await result;
    return rest
}

export async function getGroupBadge(group){

}