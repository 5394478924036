export const allFiles = [{
    "date": "14-01-2023",
    "path": "/file/14-01-2023.pdf",
    "file": require("@/commons/resources/tos/14-01-2023.pdf"),
}, {
    "date": "15-01-2023",
    "path": "/file/15-01-2023.pdf",
    "file": require("@/commons/resources/tos/15-01-2023.pdf"),
}, {
    "date": "07-08-2023",
    "path": "/file/07-08-2023.pdf",
    "file": require("@/commons/resources/tos/07-08-2023.pdf"),
}, {
    "date": "02-08-2024",
    "path": "/file/02-08-2024.pdf",
    "file": require("@/commons/resources/tos/02-08-2024.pdf"),
}, {
    "date": "15-10-2024",
    "path": "/file/15-10-2024.pdf",
    "file": require("@/commons/resources/tos/15-10-2024.pdf"),
}, {
    "date": "08.01.2025",
    "path": "/file/08.01.2025.pdf",
    "file": require("@/commons/resources/tos/08.01.2025.pdf"),
}
]