import Parchment from 'parchment';

const config = {
    scope: Parchment.Scope.BLOCK, // O BLOCK, a seconda di come preferisci
};

const configLine = {
    scope: Parchment.Scope.INLINE, // O BLOCK, a seconda di come preferisci
};

const WidthAttribute = new Parchment.Attributor.Attribute('width', 'width', config);
const HeightAttribute = new Parchment.Attributor.Attribute('height', 'height', config);

const FloatAttribute = new Parchment.Attributor.Style('float', 'float', configLine)

export { WidthAttribute, HeightAttribute, FloatAttribute };
