// File: CustomBlots.js

import {Quill} from 'react-quill';

const BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
    static create(value) {
        const node = super.create(value);
        node.setAttribute('src', value.src || value);

        if (value.width) {
            node.setAttribute('width', value.width);
        }
        if (value.height) {
            node.setAttribute('height', value.height);
        }

        return node;
    }

    static value(node) {
        return {
            src: node.getAttribute('src'),
            width: node.getAttribute('width'),
            height: node.getAttribute('height'),
        };
    }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

export default ImageBlot;
