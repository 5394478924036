// Funzione che tenta di recuperare il titolo HTML di una pagina
export async function fetchTitle(url) {
    try {
        const response = await fetch(url);
        const html = await response.text();

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const titleTag = doc.querySelector('title');

        // Se non troviamo <title>, usiamo come fallback l’URL senza "http://" o "https://"
        const fallback = url
            .replace(/^https?:\/\//, '') // Rimuove “http://” o “https://”
            .split('/')[0]; // Se vuoi solo il dominio, prendi la porzione prima del primo slash

        const pageTitle = titleTag && titleTag.textContent.trim()
            ? titleTag.textContent.trim()
            : fallback;

        return pageTitle;
    } catch (error) {
        console.error(`Errore nel recupero del titolo per ${url}:`, error);
        // Se la richiesta fallisce, restituiamo direttamente l’URL pulito
        return url.replace(/^https?:\/\//, '').split('/')[0];
    }
}


// Funzione principale per generare i link
export async function generateLinks(originalHtml) {
    // 1) Parsing dell’HTML di origine
    const parser = new DOMParser();
    const doc = parser.parseFromString(originalHtml, 'text/html');

    // Per scorrere solo i nodi di testo
    const walker = doc.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null, false);

    // Array di promesse, dove raccogliamo i fetch da fare
    const fetchPromises = [];

    // Mappa: nodoTesto -> [ { url, start, end, title: Promise }, ... ]
    const replacementsMap = new Map();

    while (walker.nextNode()) {
        const currentNode = walker.currentNode;
        const text = currentNode.nodeValue;
        if (!text) continue;

        // 2) Individua tutti gli URL presenti nel testo (anche se circondati da tag HTML intorno altrove)
        //    qui cerchiamo in questo singolo nodo di testo.
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        let match;
        while ((match = urlRegex.exec(text)) !== null) {
            const url = match[0];
            const startIndex = match.index;
            const endIndex = startIndex + url.length;

            // Avvio l’estrazione del titolo
            const titlePromise = fetchTitle(url);

            // Salvo gli estremi e la promise del titolo
            const data = { url, startIndex, endIndex, titlePromise };
            if (!replacementsMap.has(currentNode)) {
                replacementsMap.set(currentNode, []);
            }
            replacementsMap.get(currentNode).push(data);

            // Salvo la promise per poi aspettarla insieme alle altre
            fetchPromises.push(titlePromise);
        }
    }

    // Aspettiamo che tutti i titoli siano stati recuperati
    await Promise.all(fetchPromises);

    // 3) Sostituzione vera e propria: per ogni nodo di testo che ha match
    for (const [textNode, matchDataList] of replacementsMap.entries()) {
        let textContent = textNode.nodeValue;
        // Ricostruiamo un "document fragment" per sostituire il nodo
        const fragment = document.createDocumentFragment();

        // Tenere traccia dell’ultimo punto di sostituzione
        let lastIndex = 0;

        for (const { url, startIndex, endIndex, titlePromise } of matchDataList.sort((a, b) => a.startIndex - b.startIndex)) {
            // Titolo recuperato
            const title = await titlePromise;

            // Testo prima dell’URL
            const beforeUrl = textContent.slice(lastIndex, startIndex);
            if (beforeUrl) {
                fragment.appendChild(document.createTextNode(beforeUrl));
            }

            // Crea il link <a> con il titolo
            const linkElement = document.createElement('a');
            linkElement.href = url;
            linkElement.target = '_blank';
            linkElement.rel = 'noopener noreferrer';
            linkElement.textContent = title;

            fragment.appendChild(linkElement);
            lastIndex = endIndex;
        }

        // Aggiungi l’eventuale testo rimanente dopo l’ultimo URL
        const afterLast = textContent.slice(lastIndex);
        if (afterLast) {
            fragment.appendChild(document.createTextNode(afterLast));
        }

        // Sostituisce il vecchio nodo di testo col frammento
        textNode.parentNode.replaceChild(fragment, textNode);
    }

    // 4) Restituisce il nuovo HTML con i link trasformati
    return doc.body.innerHTML;
}