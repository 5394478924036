import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";

//PAGES
import HomePage from "@/www/pages/homepage/HomePage";
import Access from "@/www/pages/access/Access";
import Profile from "@/www/pages/profile/Profile";
import SocialPage from "@/www/pages/social/SocialPage";
import ErenBlaze from "@/www/pages/erenblaze/ErenBlaze";
import Events from "@/www/pages/events/Events";
import ServerPage from "@/www/pages/server/ServerPage";
import Java from "@/www/pages/server/serverHelp/Java";
import Bedrock from "@/www/pages/server/serverHelp/Bedrock";
import Redirect from "@/commons/utils/Components/Redirect";
import TwitchAuth from "@/www/pages/twitchAuth/TwitchAuth";
import EmailConfirm from "@/www/pages/tokens/EmailConfirm";
import RecoverPassword from "@/www/pages/tokens/RecoverPassword";
import MapError from "@/www/pages/error/errors/MapError";
import Error from "@/www/pages/error/Error";
import Tos from "@/www/pages/tos/Tos";
import FileViewer from "@/www/pages/tos/FileViewer";
import Faq from "@/www/pages/faq/Faq";
import Audios from "@/www/pages/audios/Audios";
import {environment} from "@/commons/configs/Config";

const MainApp: React.FC = () => {
    let path = window.location.pathname;

    if (!path.toLocaleLowerCase().includes("error")) {
        localStorage.setItem("errorName", "pageerror");
    }

    const appContent = createBrowserRouter(
        [{
            path: '/', element: <HomePage/>
        }, {
            path: '/feedback',
            element: <Redirect domain={"reports"} to={"tickets"} parameters={({defaultCategory: "34"})}/>
        }, {
            path: '/citta',
            element: <Redirect domain={"reports"} to={"tickets"} parameters={({defaultCategory: "67"})}/>
        }, {
            path: '/palestre',
            element: <Redirect domain={"reports"} to={"tickets"} parameters={({defaultCategory: "69"})}/>
        }, {
            path: '/betafortcraft',
            element: <Redirect domain={"reports"} to={"tickets"} parameters={({defaultCategory: "65"})}/>
        }, {
            path: '/faq', element: <Faq/>
        }, {
            path: '/audios/:connectionID', element: <Audios/>
        }, {
            path: '/home', element: <Redirect domain={environment.production ? "www" : "wwwdev"}/>
        }, {
            path: '/admin', element: <Redirect domain={environment.production ? "admin" : "admindev"}/>
        }, {
            path: '/reports', element: <Redirect domain={environment.production ? "reports" : "reportsdev"}/>
        }, {
            path: '/report', element: <Redirect domain={environment.production ? "reports" : "reportsdev"}/>
        }, {
            path: '/access', element: <Access/>
        }, {
            path: '/profile', element: <Profile/>
        }, {
            path: '/server', element: <ServerPage/>
        }, {
            path: '/server/:currentServer', element: <ServerPage/>
        }, {
            path: '/social', element: <SocialPage/>
        }, {
            path: '/erenblaze', element: <ErenBlaze/>
        }, {
            path: '/events', element: <Events/>
        }, {
            path: '/java', element: <Java/>
        }, {
            path: '/bedrock', element: <Bedrock/>
        }, {
            path: '/twitch/authorize', element: <TwitchAuth/>
        }, {
            path: '/emailConfirm/:token', element: <EmailConfirm/>
        }, {
            path: '/passwordChange/:token', element: <RecoverPassword/>
        }, {
            path: '/error', element: <Error/>
        }, {
            path: '/tos', element: <Tos/>
        }, {
            path: '/file/:nome', element: <FileViewer/>
        }, {
            path: '/mapError', element: <MapError/>
        }, {
            path: '*', element: <Error errorCode={"notfound"}/>
        }]
    )
    return (
        <RouterProvider router={appContent}/>
    )
}

export default MainApp;