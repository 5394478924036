import React, { useState, useRef } from "react";
import Editor from "@/reports/editor/Editor";
import ServerPreview from "@/www/pages/server/objects/Main/ServerPreview";
import "./ServersList.css";
import FileHandler from "@/www/pages/server/objects/file/FileHandler";
import {useInfoBox} from "@/commons/infoBox/InfoBox";

/** //TODO: completare la logica di salvataggio pagina/preview */
async function saveServerPage(serverObj, name, addInfo) {
    await FileHandler.saveFile(serverObj, name, addInfo)
}

/** //TODO: completare la logica di eliminazione del server */
function deleteServer(name) {

}

/** Verifica se l'utente è staff (placeholder). */
function isUserStaff() {
    return true; // Placeholder
}


class ServerObj {
    constructor(title, description, preview, name, addInfo) {
        this.title = title;
        this.description = description;
        this.preview = preview
            ? new ServerPreview(preview.title, preview.description)
            : null;

        // Mantenere “name” per salvare/eliminare il file
        this.name = name;
        this.addInfo = addInfo;
    }

    renderer() {
        const WrappedComponent = () => {
            // Stati per la “page” e la “preview”
            const [isEditingPage, setIsEditingPage] = useState(false);
            const [isPreviewingPage, setIsPreviewingPage] = useState(false);

            const [isEditingPreview, setIsEditingPreview] = useState(false);
            const [isPreviewingPreview, setIsPreviewingPreview] = useState(false);

            // Stato per la modifica del “title”
            const [isEditingTitle, setIsEditingTitle] = useState(false);
            const [tempTitle, setTempTitle] = useState(this.title);

            // Contenuto “page” e “preview”
            const [pageHtml, setPageHtml] = useState(this.description);
            const [previewHtml, setPreviewHtml] = useState(
                this.preview?.description ?? ""
            );

            const editorRef = useRef(null);

            /*******************************************
             * AZIONI SU “PAGE”
             *******************************************/
            const handleEditPage = () => {
                setIsEditingPage(true);
                setIsPreviewingPage(false);

                setIsEditingPreview(false);
                setIsPreviewingPreview(false);
                setIsEditingTitle(false);
            };

            const handlePreviewPage = () => {
                setIsEditingPage(false);
                setIsPreviewingPage(true);
            };

            const handleBackToEditPage = () => {
                setIsEditingPage(true);
                setIsPreviewingPage(false);
            };

            const handleSavePage = () => {
                this.description = pageHtml;
                setIsEditingPage(false);
                setIsPreviewingPage(false);
                saveServerPage(this, this.name, this.addInfo);
            };

            /*******************************************
             * AZIONI SU “PREVIEW”
             *******************************************/
            const handleEditPreview = () => {
                setIsEditingPreview(true);
                setIsPreviewingPreview(false);

                setIsEditingPage(false);
                setIsPreviewingPage(false);
                setIsEditingTitle(false);
            };

            const handlePreviewPreview = () => {
                setIsEditingPreview(false);
                setIsPreviewingPreview(true);
            };

            const handleBackToEditPreview = () => {
                setIsEditingPreview(true);
                setIsPreviewingPreview(false);
            };

            const handleSavePreview = () => {
                if (this.preview) {
                    this.preview.description = previewHtml;
                }
                setIsEditingPreview(false);
                setIsPreviewingPreview(false);
                saveServerPage(this, this.name, this.addInfo);
            };

            /*******************************************
             * AZIONI SU “TITLE” (NOME VISIBILE)
             *******************************************/
            const handleEditTitle = () => {
                setIsEditingTitle(true);

                setIsEditingPage(false);
                setIsPreviewingPage(false);
                setIsEditingPreview(false);
                setIsPreviewingPreview(false);
            };

            const handleSaveTitle = () => {
                this.title = tempTitle;
                setIsEditingTitle(false);
            };

            /*******************************************
             * ELIMINAZIONE SERVER
             *******************************************/
            const handleDelete = () => {
                deleteServer(this);
            };

            /*******************************************
             * VISUALIZZAZIONE PULSANTI
             *******************************************/
            const showBaseButtons =
                isUserStaff() &&
                !isEditingPage &&
                !isPreviewingPage &&
                !isEditingPreview &&
                !isPreviewingPreview &&
                !isEditingTitle;

            const showEditingPageButtons = isUserStaff() && isEditingPage;
            const showPreviewPageButtons = isUserStaff() && isPreviewingPage;

            const showEditingPreviewButtons = isUserStaff() && isEditingPreview;
            const showPreviewPreviewButtons = isUserStaff() && isPreviewingPreview;

            return (
                <div className="serverPage__modComplete" style={{ position: "relative" }}>
                    {isUserStaff() && (
                        <div className="serverPage__editButtons">
                            {showBaseButtons && (
                                <>
                                    <button onClick={handleEditTitle}>Modifica Nome</button>
                                    <button onClick={handleEditPreview}>Edit Preview</button>
                                    <button onClick={handleEditPage}>Edit Page</button>
                                    <button onClick={handleDelete}>Elimina</button>
                                </>
                            )}

                            {showEditingPageButtons && (
                                <>
                                    <button onClick={handlePreviewPage}>Anteprima</button>
                                    <button onClick={handleSavePage}>Salva</button>
                                </>
                            )}
                            {showPreviewPageButtons && (
                                <>
                                    <button onClick={handleBackToEditPage}>Edit</button>
                                    <button onClick={handleSavePage}>Salva</button>
                                </>
                            )}

                            {showEditingPreviewButtons && (
                                <>
                                    <button onClick={handlePreviewPreview}>Anteprima</button>
                                    <button onClick={handleSavePreview}>Salva</button>
                                </>
                            )}
                            {showPreviewPreviewButtons && (
                                <>
                                    <button onClick={handleBackToEditPreview}>Edit</button>
                                    <button onClick={handleSavePreview}>Salva</button>
                                </>
                            )}

                            {isEditingTitle && (
                                <>
                                    <input
                                        type="text"
                                        value={tempTitle}
                                        onChange={(e) => setTempTitle(e.target.value)}
                                    />
                                    <button onClick={handleSaveTitle}>Salva</button>
                                    <button onClick={() => setIsEditingTitle(false)}>Annulla</button>
                                </>
                            )}
                        </div>
                    )}

                    {/* Editor per la pagina */}
                    {isEditingPage && (
                        <div className="editor__container">
                            <Editor
                                ref={editorRef}
                                value={pageHtml}
                                onChange={(value) => setPageHtml(value)}
                            />
                        </div>
                    )}

                    {/* Editor per la preview */}
                    {isEditingPreview && (
                        <div className="editor__container">
                            <Editor
                                ref={editorRef}
                                value={previewHtml}
                                onChange={(value) => setPreviewHtml(value)}
                            />
                        </div>
                    )}

                    {/* Anteprima pagina */}
                    {isPreviewingPage && (
                        <div className="serverPage__mod_text">
                            <span className="serverPage__mod_title">{this.title}</span>
                            <span
                                className="serverPage__mod_description"
                                dangerouslySetInnerHTML={{ __html: pageHtml }}
                            />
                        </div>
                    )}

                    {/* Anteprima preview */}
                    {isPreviewingPreview && (
                        <div className="serverPage__mod_text">
                            <span className="serverPage__mod_title">{this.title}</span>
                            <span
                                className="serverPage__mod_description"
                                dangerouslySetInnerHTML={{ __html: previewHtml }}
                            />
                        </div>
                    )}

                    {/* Vista normale se non si modifica né “page” né “preview” né “title” */}
                    {!isEditingPage &&
                        !isPreviewingPage &&
                        !isEditingPreview &&
                        !isPreviewingPreview &&
                        !isEditingTitle && (
                            <div className="serverPage__mod_text">
                                <span className="serverPage__mod_bigtitle">{this.title}</span>
                                <span
                                    className="serverPage__mod_description"
                                    dangerouslySetInnerHTML={{ __html: this.description }}
                                />
                            </div>
                        )}
                </div>
            );
        };

        return <WrappedComponent />;
    }

    previewRender() {
        if(this.preview){
            return this.preview?.renderer();
        }else{
            this.preview = new ServerPreview(this.title, this.description)
            return this.preview?.renderer();
        }
    }
}

export default ServerObj;