import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';

export async function getServersFiles() {
    const result = fetch(environment.apiUrlTwo + '/servers/getFiles/', {
        method: 'GET',
    })
    const rest = await result;

    if (rest.status === 200) {
        return await rest.json()
    }
    return null;
}

export async function getServerFile(serverName) {
    const result = fetch(environment.apiUrlTwo + '/servers/getFile/'+serverName+'/', {
        method: 'GET',
    })
    const rest = await result;

    if (rest.status === 200) {
        return await rest.json()
    }
    return null;
}

export async function saveServerFile(serverName, content){
    let token = Cookies.get(environment.tokenName);

    const result = fetch(environment.apiUrlTwo + '/servers/saveFile/'+serverName+'/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: token
        },
        body: content
    })
    const rest = await result;

    if (rest.status === 200) {
        return true
    }
    return false;
}