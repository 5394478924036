const production = process.env.REACT_APP_PRODUCTION === "true";

const apiUrl = process.env.REACT_APP_API_URL ?? "https://apidev.eblcraft.it/";
const apiUrlOne = apiUrl + "v1";
const apiUrlTwo = apiUrl + "v2";

const mainUrl = process.env.REACT_APP_MAIN_SITE_URL ?? "https://wwwdev.eblcraft.it/";
const reportsUrl = process.env.REACT_APP_REPORTS_SITE_URL ?? "https://reportsdev.eblcraft.it/";
const adminUrl = process.env.REACT_APP_REPORTS_SITE_URL ?? "https://admindev.eblcraft.it/";

const tebexPublic = process.env.NUXT_PUBLIC_API_PUBLIC_KEY ?? "izgy-79171f5bedcae3912c70b972ecab3d16cd0c1633";
const tebexPrivate = process.env.NUXT_API_PRIVATE_KEY ?? "m57GaYdZkBcJHeL2bFgLE7k3VwIL032m";



let tokenName = "token";

export const environment = {
    production: production,
    tokenName: tokenName,
    apiUrl: apiUrl,
    apiUrlOne: apiUrlOne,
    apiUrlTwo: apiUrlTwo,
    mainUrl: mainUrl,
    reportsUrl: reportsUrl,
    adminUrl: adminUrl
};;