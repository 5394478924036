import "react-quill/dist/quill.snow.css"
import "./ReactQuill-Custom.css"
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

import ReactQuill, {Quill} from 'react-quill';
import ImageBlot from './blot/ImageBlot';

// Stile base di Quill
import 'react-quill/dist/quill.snow.css';
import {useInfoBox} from "@/commons/infoBox/InfoBox";
import ResizeModule from '@botom/quill-resize-module';
import {HeightAttribute, WidthAttribute, FloatAttribute} from './attributes/CustomAttributors';

const AlignStyle = Quill.import('attributors/style/align');
const SizeStyle = Quill.import('attributors/style/size');
const FontStyle = Quill.import('attributors/style/font');
const ColorStyle = Quill.import('attributors/style/color');

const BackgroundStyle = Quill.import('attributors/style/background');

function Editor(props: any, ref: any) {
    const { addInfo } = useInfoBox()
    const { value, onChange } = props;
    const quillRef = useRef<ReactQuill>(null);

    Quill.register(AlignStyle, true);
    Quill.register(FontStyle, true);
    Quill.register(ColorStyle, true);
    Quill.register(BackgroundStyle, true);

    Quill.register(ImageBlot, true); // Registra il blot con Quill

    Quill.register(FloatAttribute, true);
    Quill.register(WidthAttribute, true);
    Quill.register(HeightAttribute, true);

    let Block = Quill.import('blots/block');
    Block.tagName = 'div';
    Block.style = "width: 100%";
    Quill.register(Block, true);

    Quill.register('modules/resize', ResizeModule, true);

    SizeStyle.whitelist = [
        '10px',
        '12px',
        '14px',
        '16px',
        '18px',
        '24px',
        '36px',
        '48px'
    ];
    Quill.register(SizeStyle, true);

    const [editorValue, setEditorValue] = useState<string>(value || '');

    const formats = [
        'resizable-image',
        'bold', 'italic', 'underline', 'strike',
        'align',
        'list', 'indent',
        'color', 'background',
        'font', 'size',
        'link', 'image', 'blockquote', 'code-block'
    ]

    const modules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'align': [] }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'blockquote', 'code-block'],
                [{'font': []}, {'size': ['10px', '12px', '14px', '16px', '18px', '24px', '36px', '48px']}],
                ['clean'],
            ],
        },
        resize: {
            modules: ['Resize', 'DisplaySize'] // Opzioni per il modulo, se applicabili
        }
    };

    function handleDrop(e: any) {
        if (!quillRef.current) return;
        if (!e.dataTransfer) return;

        e.preventDefault(); // Solo dopo il controllo
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            const item = e.dataTransfer.items[i];
            handleFile(item)
        }
    }

    function handlePaste(event: any) {
        if (!quillRef.current) return;
        if (!event.clipboardData) return;

        for (let i = 0; i < event.clipboardData.items.length; i++) {
            const item = event.clipboardData.items[i];
            if (item.kind === "string"){
            }else{
                event.preventDefault(); // Spostato dopo i controlli
                handleFile(item)
            }
        }
    }

    function handleFile(item:any){
        if (item.kind === "file") {
            const file = item.getAsFile(); // Converti DataTransferItem in File
            addFile(file);
        } else {
            addInfo("alert", "Errore", "Quello che hai caricato non sembra essere un file!", 10)
        }
    }

    function addFile(file:any){
        // Check if dropped file is an image
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = () => {
                const current = quillRef.current;
                if(current == null){
                    addInfo("alert", "Errore", "C'è stato un problema durante il load dell'immagine")
                    return
                }
                const quill = current.editor;
                if(quill == null){
                    addInfo("alert", "Errore", "C'è stato un problema durante il load dell'immagine")
                    return
                }
                let range: any = quill.getSelection() == null ? 0 : quill.getSelection();
                const img = reader.result;

                quill.insertEmbed(range.index, "image", img);
            };
            reader.readAsDataURL(file);
        } else {
        }
    }

    // Aggiorna lo stato quando l'editor cambia
    function handleChange(content: string) {
        setEditorValue(content);
        if (onChange) onChange(content);
    }

    // Espone un metodo getHTML() al genitore
    useImperativeHandle(ref, () => ({
        getHTML: () => {
            const editor = quillRef.current?.getEditor();
            if (!editor) return '';
            return editor.root.innerHTML;
        }
    }));

    useEffect(() => {
        setEditorValue(value || '');
    }, [value]);

    return (
        <div onDrop={handleDrop} onPaste={handlePaste}>
        <ReactQuill
            ref={quillRef}
            value={editorValue}
            onChange={handleChange}
            formats={formats}
            modules={modules}
            theme="snow"
            style={{ minHeight: '200px' }}
        />
        </div>
    );
}

export default forwardRef(Editor);