import {encoded} from "../base64/Encoder";
import ApiInterface from "../api/ApiInterface";
import {generateLinks} from "../links/LinksExtractor";
import {useInfoBox} from "@/commons/infoBox/InfoBox";

export async function getReportText(quillText, formData, addInfo) {
    if (formData == null) {
        return null;
    }

    let htmlWithImages = await uploadImages(quillText, addInfo);
    if (htmlWithImages == null) {
        return null;
    }
    const text = formData + htmlWithImages;

    return encoded(text);
}

export async function checkText(text){
    if (isOnlyHtmlTags(text)) {
        if (text.includes("<img"))
            return encoded(text);
        return "empty"
    }
    return encoded(text);
}

export async function getResponseText(quillText, addInfo) {
    let updatedText = await uploadImages(quillText, addInfo);
    if (updatedText === null) {
        return null;
    }

    updatedText = await generateLinks(updatedText)
    if (isOnlyHtmlTags(updatedText)) {
        if (updatedText.includes("<img"))
            return encoded(updatedText);
        return "empty"
    }
    return encoded(updatedText);
}

async function uploadImages(text, addInfo) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const imgTags = doc.getElementsByTagName('img');

    for (let i = 0; i < imgTags.length; i++) {
        const img = imgTags[i];
        const src = img.getAttribute('src');

        // Verifica se l'immagine è una data URI
        if (src.startsWith('data:image')) {
            try {
                // Estrai il tipo MIME e i dati base64
                const dataURI = src;
                const mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
                const bytes = window.atob(dataURI.split(',')[1]);
                const data = new Uint8Array(bytes.length);

                // Converte la stringa base64 in binario
                for (let j = 0; j < bytes.length; j++) {
                    data[j] = bytes.charCodeAt(j);
                }

                const extension = mimeType.split("/")[1];
                const file = new File([data], `image.${extension}`, { type: mimeType });

                // Usa la funzione uploadFile di ApiInterface per caricare l'immagine
                const imageResponse = await ApiInterface.uploadFile(file, "reports/posts/attachments/images", addInfo);

                if (imageResponse) {
                    img.setAttribute('src', imageResponse); // Imposta l'URL ritornato come src dell'immagine
                } else {
                    console.error("Errore durante il caricamento dell'immagine!");
                }
            } catch (err) {
                console.error("Errore durante la conversione o il caricamento dell'immagine:", err);
            }
        }
    }

    return doc.documentElement.innerHTML;
}

function isOnlyHtmlTags(text) {
    return text.replace(/<[^>]*>?/gm, '').trim() === '';
}