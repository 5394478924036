import React, {FC, useEffect, useState} from "react";
import ApiInterface from "@/commons/api/ApiInterface";
import { useInfoBox } from "@/commons/infoBox/InfoBox";

interface UserProfileProps {
    profile: any; // Puoi definire il tipo esatto del profilo, ad esempio un'interfaccia TypeScript per `profile`.
    viewer: any; // Tipo del `viewer`, se disponibile.
    roles: any; // Gestione ruoli con metodi e proprietà (es. `roles.getLength()`).
}

const UserProfileObject: FC<UserProfileProps> = ({ profile, viewer, roles }) => {
    const { addInfo } = useInfoBox();
    const [roleImage, setRoleImage] = useState<string | undefined>("");

    const updateSelf = async () => {
        let updatedUser = await ApiInterface.getLightUser(profile.uid);
        updateProfile(updatedUser);
    }

    const updateProfile = (updatedJson:any) =>{
        profile.updateAll(updatedJson);
        updateSelect()
        loadRoleImage();
    }

    const updateSelect = () => {
        const select = document.querySelector<HTMLSelectElement>(
            `.profileManager__userItem[data-uid="${profile.uid}"] .profileManager__userItemActionRoleSelect`
        );
        if (select) {
            select.value = profile.staffGroup?.groupName || ""; // Valore aggiornato
        }
    }

    // Funzione per aggiornare il ruolo
    const handleRoleChange = (newRole: string) => {
        ApiInterface.updateRole(profile.uid, newRole).then((res) => {
            if (res.status !== 200) {
                addInfo("alert", "Errore", "C'è stato un errore durante l'aggiornamento del ruolo dell'utente!", 5);
            } else {
                addInfo("success", "Ruolo aggiornato", "Il ruolo dell'utente è stato aggiornato correttamente!", 5);
                updateSelf();
            }
        });
    };

    // Funzione per gestire i ban
    const handleBanAction = () => {
        const action = profile.status?.toUpperCase() !== "BANNED" ? "banuser" : "unbanuser";
        const successMessage = action === "banuser" ? "Bannato" : "Sbannato";
        ApiInterface[action](profile.uid).then((res) => {
            if (res.status !== 200) {
                addInfo("alert", "Errore", "C'è stato un errore durante la richiesta!", 5);
            } else {
                addInfo("success", successMessage, `L'utente è stato ${successMessage.toLowerCase()} correttamente!`, 5);
            }
            updateSelf();
        });
    };

    // Calcolo delle autorizzazioni ruolo
    const viewerPower = viewer?.staffGroup?.groupPower || 0;
    const userPower = profile.staffGroup?.groupPower || 0;

    const loadRoleImage = async () => {
        setRoleImage("");
        let imageLink: string | null | undefined = await ApiInterface.loadRoleImage(profile.staffGroup.id);
        if(imageLink == null) imageLink = undefined;
        setRoleImage(imageLink);
    }

    useEffect(() => {
        loadRoleImage();
    }, []);

    return (
        <div className="profileManager__userItem" data-uid={profile.uid}>
            <div className="profileManager__userItemUsername">
                {profile.username}
                <br />
                <a style={{ color: "#b90000" }} href={`/user/${profile.id}/`}>
                    Visualizza profilo
                </a>
            </div>
            <div className="profileManager__userItemEmail">{profile.email}</div>
            <div className="profileManager__userItemRole">
                <img
                    src={roleImage}
                    alt="Role"
                    className="profileManager__userItemRoleImage"
                />
            </div>
            <div className="profileManager__userItemActions">
                <div className="profileManager__userItemActionTitle">Gestione utente</div>
                <button
                    className="profileManager__userItemActionBan"
                    onClick={handleBanAction}
                >
                    {profile.status?.toUpperCase() !== "BANNED" ? "Banna" : "Sbanna"}
                </button>
                <div className="profileManager__userItemActionRole">
                    <select
                        className="profileManager__userItemActionRoleSelect"
                        onChange={(e) => handleRoleChange(e.target.value)}
                        defaultValue={profile.staffGroup?.groupName}
                    >
                        {roles?.getLength
                            ? Array.from({ length: roles.getLength() }).map((_, i) => {
                                const role = roles.getRole(i);
                                return (
                                    <option
                                        key={role.groupName}
                                        value={role.groupName}
                                        disabled={
                                            userPower > viewerPower ||
                                            viewerPower <= role.groupPower ||
                                            role.groupName === profile.staffGroup?.groupName
                                        }
                                    >
                                        {role.groupName}
                                    </option>
                                );
                            })
                            : null}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default UserProfileObject;