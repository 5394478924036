// "ReportsHome.jsx"

import React from "react";
import styles from "./ReportsHome.module.css"; // i tuoi stili utente
import CommonReportsPage from "@/commons/tickets/CommonReportsPage";
import { environment } from "@/commons/configs/Config";
import Cookies from "js-cookie";

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

/** Funzione fetch per i report utente */
async function fetchUserReports(filter, page, itemsPerPage) {
    const userToken = Cookies.get(environment.tokenName);
    const url = `${environment.apiUrlTwo}/posts/getFiltered/?status=${filter}`;
    const pageData = { page, items: itemsPerPage };

    const resp = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(pageData),
    });
    if (resp.status === 200) {
        return await resp.json(); // {content: [...], totalElements: number}
    } else {
        return { content: [], totalElements: 0 };
    }
}

function ReportsHome() {
    function handleReportClick(reportId, event) {
        event.preventDefault();
        window.location.href = environment.reportsUrl + "post/" + reportId;
    }

    return (
        <>
            <Background />
            <div className={styles.page__body}>
                <Header marginTop={1} marginBottom={1} />
                <div className={styles.page__content}>
                    <div className={styles.MainUser_ReportsMainBox}>
                        <br />
                        {/* Qui dentro puoi mettere un h1 se vuoi,
                oppure lascia che ci pensi CommonReportsPage con pageTitle="I miei Reports" */}
                        <CommonReportsPage
                            pageTitle="I miei Reports"
                            fetchReports={fetchUserReports}
                            onReportClick={handleReportClick}
                            newReportButtonUrl="/tickets"
                        />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default ReportsHome;